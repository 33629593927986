.container {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2%;
    font-family: var(--main-font);
    box-sizing: border-box;
}

.container h1 {
    font-family: var(--main-font);
    color: var(--primary-color);
    font-size: 6rem;
    text-align: center;
}

.cardContainer {
    display: flex;
    justify-content: center;
    color: var(--text-on-dark);
    gap: 1rem;
}

.cardContainer p {
    display: flex;
    width: 80%;
    max-width: 650px;
    aspect-ratio: 3 / 1;
    background-color: var(--secondary-color);
    padding: 6%;
    box-shadow: var(--card-shadow);
    margin-left: -5%;
    z-index: -1;
}

.cardContainer p span {
    margin-top: auto;
    margin-bottom: auto;
    font-size: larger;
}

.imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 220px;
}

.cardContainer img {
    max-width: 100%;
    object-fit: cover;
    aspect-ratio: 1 / 1;
    height: auto;
    border-radius: 50%;
    box-shadow: var(--card-shadow);
}