.container {
    display: flex;
    position: absolute;
    width: 100%;
    max-width: 100%;
    padding: 2%;
    box-sizing: border-box;
}

.container a {
    color: var(--text-on-dark);
    text-decoration: none;
    padding: 1%;
}

.container a.link {
    margin-left: auto;
}