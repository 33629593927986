.container {
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    background: url('../images/sunset-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-blend-mode: soft-light;
    background-attachment: fixed;
    font-family: var(--main-font);
    position: relative;
    overflow: hidden;

}

.container .overlay {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(227, 148, 11, 0.81);
}

.container h1 {
    font-size: 4rem;
    line-height: 2.5rem;
    color: var(--text-on-dark);
}

.container h2 {
    font-size: 1.5rem;
    color: var(--text-on-dark);
}