.container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    padding-left: 6%;
    font-family: var(--main-font);
    box-sizing: border-box;
}

.container a h1 {
    font-family: var(--main-font);
    color: var(--primary-color);
    font-size: 4rem;
}

.container h1 {
    font-family: var(--main-font);
    color: var(--primary-color);
    font-size: 4rem;
}

.container h3 {
    font-family: var(--main-font);
    color: var(--highlight);
}